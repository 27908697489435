<template>
  <div id="pageRomaneio">
    <v-container grid-list-xl fluid>
      <v-layout row wrap justify-center>
        <v-flex lg10 sm12 xs12>
          <romaneio-table></romaneio-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import RomaneioTable from "@/components/romaneio/tableRomaneio"
export default {
  components: {
    RomaneioTable
  }
}
</script>
